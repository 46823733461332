<template>
  <div class="campaignPromoters">
    <ul class="campaignPromoters__links" v-if="campaignStats">
      <li>
        <a
          href="javascript:void(0)"
          :class="this.state == 'pending' ? 'active' : ''"
          @click="state = 'pending'"
          >Pending<span v-if="campaignStats.subscribers.pending != 0">{{
            campaignStats.subscribers.pending
          }}</span>
        </a>
      </li>
      <li>
        <a
          href="javascript:void(0)"
          :class="this.state == 'active' ? 'active' : ''"
          @click="state = 'active'"
          >Active<span v-if="campaignStats.subscribers.active != 0">{{
            campaignStats.subscribers.active
          }}</span></a
        >
      </li>
      <li>
        <a
          href="javascript:void(0)"
          :class="this.state == 'revision' ? 'active' : ''"
          @click="state = 'revision'"
          >Revision
          <span v-if="campaignStats.subscribers.revision != 0">{{
            campaignStats.subscribers.revision
          }}</span></a
        >
      </li>
      <li>
        <a
          href="javascript:void(0)"
          :class="this.state == 'completed' ? 'active' : ''"
          @click="state = 'completed'"
          >Completed
          <span v-if="campaignStats.subscribers.completed != 0">{{
            campaignStats.subscribers.completed
          }}</span></a
        >
      </li>
    </ul>
    <h2 v-if="campaignSubscribers">
      {{ campaignSubscribers.length }} local influencer
    </h2>
    <div v-if="$apollo.queries.campaignSubscribers.loading">
      <base-skeleton-loader type="localInfluencers"></base-skeleton-loader>
    </div>
    <div v-if="norecord">No Record Found</div>
    <div v-if="!$apollo.queries.campaignSubscribers.loading && !norecord">
      <campaign-promoters-card
        v-for="(subscriber, i) in campaignSubscribers"
        :key="i + 1"
        :subscriber="subscriber"
        @accept="acceptSusbscriber"
        @decline="rejectSubscriber"
      />
    </div>
    <div v-if="campaignSubscribers">
      <div
        class="campaignPromoters__budget"
        v-if="campaignSubscribers.length != 0"
      >
        <p v-if="campaignStats">
          Budget: <span>{{ campaignStats.budgetUsed }}</span>
        </p>
        <a
          href="javascript:void(0)"
          v-if="this.state == 'revision' || this.state == 'pending'"
          class="btn btn-green"
          @click="approveAll"
          >Accept All</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { CAMPAIGN_STATS, CAMPAIGN_SUBSCRIBERS } from "@/graphql/brand/query";
import {
  APPROVE_SUBSCRIPTION,
  REJECT_SUBSCRIPTION,
  CAMPAIGN_DELIVERY_APPROVE,
} from "@/graphql/brand/mutations";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { MESSAGES } from "@/_helpers/notifications";
export default {
  data() {
    return {
      norecord: false,
      state: "active",
      id: [],
      approveMutation: CAMPAIGN_DELIVERY_APPROVE,
    };
  },
  props: {
    campaignpromoters: [Object, Array],
  },
  methods: {
    refetch() {
      this.$apollo.queries.campaignSubscribers.refresh();
      this.$apollo.queries.campaignStats.refresh();
    },
    async approveAll() {
      this.id = [];
      let i = 0;
      for (i = 0; i <= this.campaignSubscribers.length - 1; i++) {
        this.id.push(this.campaignSubscribers[i].id);
      }
      await this.$apollo
        .mutate({
          mutation: this.approveMutation,
          variables: {
            id: this.id,
          },
        })
        .then((data) => {
          if (data) {
            let state =
              this.state == "revision"
                ? data.data.campaignDeliveryApprove.state
                : data.data.approveSubscription.state;
            let msg =
              this.state == "revision"
                ? data.data.campaignDeliveryApprove.msg
                : data.data.approveSubscription.msg;
            if (state == "success") {
              Notify.success(msg);
              this.refetch();
            }
          }
        })
        .catch((e) => {
          this.handleError(e);
        });
    },
    async acceptSusbscriber(id) {
      this.id = [];
      this.id.push(id);
      await this.$apollo
        .mutate({
          mutation: this.approveMutation,
          variables: {
            id: this.id,
          },
        })
        .then((data) => {
          if (data) {
            let state =
              this.state == "revision"
                ? data.data.campaignDeliveryApprove.state
                : data.data.approveSubscription.state;
            let msg =
              this.state == "revision"
                ? data.data.campaignDeliveryApprove.msg
                : data.data.approveSubscription.msg;
            if (state == "success") {
              Notify.success(msg);
              this.refetch();
            }
          }
        })
        .catch((e) => {
          this.handleError(e);
        });
    },
    async rejectSubscriber(id) {
      this.id = [];
      this.id.push(id);
      await this.$apollo
        .mutate({
          mutation: REJECT_SUBSCRIPTION,
          variables: {
            id: this.id,
          },
        })
        .then((data) => {
          if (data) {
            if (data.data.rejectSubscription.state == "success") {
              Notify.success(data.data.rejectSubscription.msg);
              this.refetch();
            }
          }
        })
        .catch((e) => {
          this.handleError(e);
        });
    },
  },
  apollo: {
    campaignStats: {
      query: CAMPAIGN_STATS,
      variables() {
        return {
          id: parseInt(this.$route.params.id),
        };
      },
    },
    campaignSubscribers: {
      query: CAMPAIGN_SUBSCRIBERS,
      variables() {
        return {
          id: parseInt(this.$route.params.id),
          state: this.state,
        };
      },
      error(e) {
        this.handleError(e);
      },
    },
  },
  components: {
    CampaignPromotersCard: () =>
      import(
        /* webpackChunkName: "campaignPromotersCard" */ "./partials/CampaignPromotersCard.vue"
      ),
  },
  watch: {
    state() {
      this.approveMutation =
        this.state == "revision"
          ? CAMPAIGN_DELIVERY_APPROVE
          : APPROVE_SUBSCRIPTION;
    },
  },
};
</script>

<style lang="scss" scoped>
.campaignPromoters {
  position: relative;
  &__links {
    list-style: none;
    position: absolute;
    right: 0;
    top: -62px;
    margin-bottom: rem(25px);
    @include flex(center, start);
    @media screen and (max-width: 991px) {
      position: static;
      width: 100%;
      justify-content: space-between;
      margin: 0;
    }
    li {
      &:not(:last-child) {
        margin-right: rem(14px);
      }
      a {
        position: relative;
        @include flex(center, center);
        font-size: rem(14px);
        font-weight: 700;
        color: #060606;
        background: transparent;
        padding: rem(8px);
        border-radius: 6px;
        border: 2px solid #e0e5ec;
        min-width: 120px;
        height: 40px;
        text-decoration: unset !important;
        &.active {
          border: 2px solid #9637f1;
        }
        span {
          position: absolute;
          right: 8px;
          top: 8px;
          width: 12px;
          height: 12px;
          background: #fc1524;
          border-radius: 50%;
          @include flex(center, center);
          font-size: 7px;
          font-weight: 700;
          color: #fff;
          @media screen and (max-width: 400px) {
            right: 10px;
          }
        }
      }
      @media screen and (max-width: 767px) {
        margin-bottom: 10px;
        width: 22%;
        a {
          min-width: auto;
        }
      }
      @media screen and (max-width: 667px) {
        margin-bottom: 10px;
        width: 40%;
        &:not(:last-child) {
          margin-right: 0;
        }
      }
    }
  }
  h2 {
    font-size: rem(18px);
    font-weight: 700;
    padding-left: rem(16px);
    margin-bottom: rem(20px);
    @media screen and (max-width: 991px) {
      margin-top: rem(45px);
    }
  }
  &__budget {
    margin-top: rem(16px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 rem(30px) 0 rem(10px);
    p {
      font-size: rem(18px);
      font-family: $secondary-font;
      color: var(--textPrimary);
      font-weight: 700;
      margin: 0;
      span {
        font-weight: 500;
      }
    }
    .btn-green {
      padding-left: rem(25px);
      padding-right: rem(25px);
    }
  }
}
</style>
