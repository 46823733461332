<template>
  <div class="campaignPromoters" v-if="offerSubscribers">
    <ul class="campaignPromoters__links">
      <li>
        <a
          href="javascript:void(0)"
          @click.prevent="state = 'active'"
          :class="this.state == 'active' ? 'active' : ''"
          >Active</a
        >
      </li>
      <li>
        <a
          href="javascript:void(0)"
          @click.prevent="state = 'pending'"
          :class="this.state == 'pending' ? 'active' : ''"
          >Pending</a
        >
      </li>

      <li>
        <a
          href="javascript:void(0)"
          @click.prevent="state = 'revision'"
          :class="this.state == 'revision' ? 'active' : ''"
          >Revision</a
        >
      </li>
      <li>
        <a
          href="javascript:void(0)"
          @click.prevent="state = 'completed'"
          :class="this.state == 'completed' ? 'active' : ''"
          >Completed</a
        >
      </li>
    </ul>
    <h2 v-if="offerSubscribers">
      {{ offerSubscribers.length }} local influencer
    </h2>
    <div v-if="$apollo.queries.loading">
      <base-skeleton-loader type="localInfluencers"></base-skeleton-loader>
    </div>
    <div v-if="!$apollo.queries.offerSubscribers.loading && !norecord">
      <deal-promoters-card
        v-for="(subscriber, i) in offerSubscribers"
        :key="i + 1"
        :subscriber="subscriber"
      ></deal-promoters-card>
    </div>
    <div v-if="offerSubscribers">
      <div
        class="campaignPromoters__budget justify-content-end"
        v-if="offerSubscribers.length != 0"
      >
        <a href="#" class="btn btn-green">Accept All</a>
      </div>
    </div>
  </div>
  <div class="no-record" v-else>No Record Found</div>
</template>

<script>
import { OFFER_SUBSCRIBERS } from "@/graphql/brand/query";
export default {
  data() {
    return {
      norecord: false,
      state: "active",
      id: [],
    };
  },
  apollo: {
    offerSubscribers: {
      query: OFFER_SUBSCRIBERS,
      variables() {
        return {
          id: parseInt(this.$route.params.id),
          state: this.state,
        };
      },
      error(e) {
        this.handleError(e);
      },
    },
  },
  components: {
    DealPromotersCard: () =>
      import(
        /* webpackChunkName: "campaignPromotersCard" */ "./partials/DealPromotersCard.vue"
      ),
  },
};
</script>

<style lang="scss" scoped>
.campaignPromoters {
  position: relative;
  &__links {
    list-style: none;
    position: absolute;
    right: 0;
    top: -62px;
    margin-bottom: rem(25px);
    @include flex(center, start);
    @media screen and (max-width: 991px) {
      position: static;
      width: 100%;
      justify-content: space-between;
      margin: 0;
    }
    li {
      &:not(:last-child) {
        margin-right: rem(14px);
      }
      a {
        position: relative;
        @include flex(center, center);
        font-size: rem(14px);
        font-weight: 700;
        color: #060606;
        background: transparent;
        padding: rem(8px);
        border-radius: 6px;
        border: 2px solid #e0e5ec;
        min-width: 120px;
        height: 40px;
        text-decoration: unset !important;
        &.active {
          border: 2px solid #9637f1;
        }
        span {
          position: absolute;
          right: 8px;
          top: 8px;
          width: 12px;
          height: 12px;
          background: #fc1524;
          border-radius: 50%;
          @include flex(center, center);
          font-size: 7px;
          font-weight: 700;
          color: #fff;
          @media screen and (max-width: 400px) {
            right: 10px;
          }
        }
      }
      @media screen and (max-width: 767px) {
        margin-bottom: 10px;
        width: 22%;
        a {
          min-width: auto;
        }
      }
      @media screen and (max-width: 667px) {
        margin-bottom: 10px;
        width: 40%;
        &:not(:last-child) {
          margin-right: 0;
        }
      }
    }
  }
  h2 {
    font-size: rem(18px);
    font-weight: 700;
    padding-left: rem(16px);
    margin-bottom: rem(20px);
    @media screen and (max-width: 991px) {
      margin-top: rem(45px);
    }
  }
  &__budget {
    margin-top: rem(16px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 rem(30px) 0 rem(10px);
    p {
      font-size: rem(18px);
      font-family: $secondary-font;
      color: var(--textPrimary);
      font-weight: 700;
      margin: 0;
      span {
        font-weight: 500;
      }
    }
    .btn-green {
      padding-left: rem(25px);
      padding-right: rem(25px);
    }
  }
}
</style>
